import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Color } from '../../style/Color';

export type Props = {
	style?: CSSProperties;
	className?: string;
};

/**
 *
 * <defs><style>.a{fill:#fff;}.b{fill:#00703c;}</style></defs>
 */

const LogoHolder = styled.div`
	.cls-1 {
		fill: ${Color.HILIGHT};
	}
	.cls-2 {
		fill: ${Color.NAV_TEXT};
	}
`;

export const Logo: React.FC<Props> = props => {
	return (
		<LogoHolder style={props.style} className={`logo ${props.className}`}>
			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 351.02 436'
			>
				<title>Sloss Eckhouse Dasti Haynes LawCo.</title>
				<path
					className='cls-1'
					d='M244.53,185.82c-.1-.15-1.69-2.28-6.11-4.63s-11.64-4.92-23-6c-2.31-.22-4.58-.33-6.78-.33-6.75,0-12.87,1-17.54,3.29a16.82,16.82,0,0,0-5.79,4.38,12.08,12.08,0,0,0-2.7,6.42,13.6,13.6,0,0,0-.09,1.5,9.5,9.5,0,0,0,1.81,5.79,12.49,12.49,0,0,0,3.83,3.31,34.59,34.59,0,0,0,9.79,3.48,127.32,127.32,0,0,0,15.4,2.26c12,1.15,20.31,2.45,25.44,4.52a12.55,12.55,0,0,1,5.38,3.62,8,8,0,0,1,1.6,5.14v.15l1.25,0h-1.25a8.77,8.77,0,0,1-.72,3.5,10.5,10.5,0,0,1-4.1,4.5,29.46,29.46,0,0,1-10.57,3.7,63.21,63.21,0,0,1-10.73.9h-1a72.39,72.39,0,0,1-18.68-2.58,50,50,0,0,1-19.17-10.22,1.25,1.25,0,1,0-1.63,1.89A52.5,52.5,0,0,0,199.2,231.1a74.87,74.87,0,0,0,19.33,2.68h1.08a56.48,56.48,0,0,0,17.49-2.55,20.32,20.32,0,0,0,7.73-4.5,11.17,11.17,0,0,0,3.4-8v0h0v-.09a10.48,10.48,0,0,0-2.13-6.68,13.17,13.17,0,0,0-4.43-3.5A41.25,41.25,0,0,0,230.6,205c-4.64-.88-10.28-1.57-17.05-2.22-10.85-1-18.19-2.54-22.63-4.61a11.6,11.6,0,0,1-4.61-3.43,7,7,0,0,1-1.33-4.3,11.06,11.06,0,0,1,.07-1.23,9.56,9.56,0,0,1,2.15-5.11c1.78-2.19,4.67-3.89,8.36-5a44.91,44.91,0,0,1,13-1.71c2.12,0,4.31.11,6.55.32,11.12,1.06,18,3.54,22.1,5.72a22,22,0,0,1,4.19,2.81,10.42,10.42,0,0,1,.85.83l.17.2,0,0h0l.58-.43-.58.42h0l.58-.43-.58.42a1.25,1.25,0,0,0,2-1.46Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-1'
					d='M60.7,13a30.57,30.57,0,0,0-7.08-6.52A37,37,0,0,0,32.41,0C23.1,0,16.22,1.69,11.54,4.54a15.57,15.57,0,0,0-5.3,5.11A12.11,12.11,0,0,0,4.46,16a13.89,13.89,0,0,0,1.25,6.07,12.13,12.13,0,0,0,2.93,3.85,22.55,22.55,0,0,0,8.08,4.3,69.86,69.86,0,0,0,13.33,2.71c9,1.07,16.4,2.17,21.39,4.22A13.58,13.58,0,0,1,57,40.93,8.77,8.77,0,0,1,58.9,46.7a15.52,15.52,0,0,1-.16,2.12,12.7,12.7,0,0,1-2.92,6.65A19.25,19.25,0,0,1,46.35,61a43.44,43.44,0,0,1-11.85,1.53c-.77,0-1.53,0-2.26,0a44.25,44.25,0,0,1-14.91-2.81C12.47,57.75,7.54,54.51,2.14,49A1.25,1.25,0,0,0,.36,50.73c5.58,5.72,10.83,9.2,16,11.28a46.74,46.74,0,0,0,15.73,3c.77,0,1.56.05,2.37.05C39.82,65,45.93,64.29,51,62a19.1,19.1,0,0,0,6.68-4.86,15.22,15.22,0,0,0,3.5-7.92,18,18,0,0,0,.18-2.47A11.29,11.29,0,0,0,59,39.36a14.3,14.3,0,0,0-4.62-3.62,39.47,39.47,0,0,0-10.3-3.27c-4-.81-8.64-1.42-13.71-2-9.5-1.13-15.45-3-18.87-5.46A10.29,10.29,0,0,1,8,21a11.41,11.41,0,0,1-1-5,9.59,9.59,0,0,1,1.41-5.06c1.43-2.35,4-4.46,8-6s9.29-2.46,16-2.46a34.49,34.49,0,0,1,19.8,6,32,32,0,0,1,4.89,4.11c.54.56.94,1,1.2,1.34l.29.36.07.09v0h0a1.25,1.25,0,0,0,2-1.46Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-1'
					d='M273.67,13.22a21.28,21.28,0,0,0-5.87-6.65C263.6,3.29,256.81.05,246.62,0h-.41c-9.17,0-16,1.7-20.57,4.56a15.51,15.51,0,0,0-5.22,5.12A12.17,12.17,0,0,0,218.67,16a13.89,13.89,0,0,0,1.25,6.07,12.14,12.14,0,0,0,2.93,3.85,22.56,22.56,0,0,0,8.08,4.3,69.86,69.86,0,0,0,13.33,2.71c9,1.07,16.4,2.17,21.39,4.22a13.59,13.59,0,0,1,5.58,3.78,8.77,8.77,0,0,1,1.88,5.76,15.69,15.69,0,0,1-.15,2.13A12.7,12.7,0,0,1,270,55.47,19.25,19.25,0,0,1,260.56,61a43.44,43.44,0,0,1-11.85,1.53c-.78,0-1.53,0-2.26,0a44.25,44.25,0,0,1-14.92-2.81c-4.86-1.94-9.79-5.18-15.18-10.7a1.25,1.25,0,1,0-1.79,1.75c5.58,5.72,10.83,9.2,16,11.28a46.74,46.74,0,0,0,15.73,3c.77,0,1.56.05,2.37.05,5.32,0,11.42-.76,16.54-3.1a19.11,19.11,0,0,0,6.68-4.86,15.22,15.22,0,0,0,3.5-7.92,18.1,18.1,0,0,0,.18-2.47,11.29,11.29,0,0,0-2.43-7.33,14.28,14.28,0,0,0-4.62-3.62,39.49,39.49,0,0,0-10.3-3.27c-4-.81-8.64-1.42-13.71-2-9.5-1.13-15.45-3-18.87-5.46a10.28,10.28,0,0,1-3.51-4,11.42,11.42,0,0,1-1-5,9.67,9.67,0,0,1,1.39-5c1.41-2.35,4-4.46,7.86-6s9.14-2.47,15.79-2.47h.39c9.67.06,15.85,3.08,19.66,6a22,22,0,0,1,4,4,15.25,15.25,0,0,1,.89,1.3l.2.34,0,.08v0l.23-.11-.23.11h0l.23-.11-.23.11a1.25,1.25,0,1,0,2.25-1.1Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-1'
					d='M347.16,13a30.57,30.57,0,0,0-7.08-6.52A37,37,0,0,0,318.87,0C309.56,0,302.68,1.69,298,4.54a15.57,15.57,0,0,0-5.3,5.11A12.11,12.11,0,0,0,290.92,16a13.9,13.9,0,0,0,1.25,6.07,12.16,12.16,0,0,0,2.93,3.85,22.56,22.56,0,0,0,8.08,4.3,69.84,69.84,0,0,0,13.33,2.71c9,1.07,16.4,2.17,21.39,4.22a13.59,13.59,0,0,1,5.58,3.78,8.78,8.78,0,0,1,1.88,5.76,15.52,15.52,0,0,1-.16,2.12,12.71,12.71,0,0,1-2.92,6.65A19.26,19.26,0,0,1,332.82,61,43.44,43.44,0,0,1,321,62.55c-.77,0-1.53,0-2.26,0a44.25,44.25,0,0,1-14.91-2.81C298.93,57.75,294,54.51,288.6,49a1.25,1.25,0,1,0-1.79,1.75c5.58,5.72,10.83,9.2,16,11.28a46.74,46.74,0,0,0,15.73,3c.77,0,1.56.05,2.37.05,5.32,0,11.42-.76,16.54-3.1a19.1,19.1,0,0,0,6.68-4.86,15.22,15.22,0,0,0,3.5-7.92,17.93,17.93,0,0,0,.18-2.47,11.29,11.29,0,0,0-2.43-7.33,14.29,14.29,0,0,0-4.62-3.62,39.45,39.45,0,0,0-10.29-3.27c-4-.81-8.64-1.42-13.71-2-9.5-1.13-15.45-3-18.87-5.46a10.29,10.29,0,0,1-3.51-4,11.39,11.39,0,0,1-1-5,9.59,9.59,0,0,1,1.41-5.06c1.43-2.35,4-4.46,8-6s9.29-2.46,16-2.46a34.49,34.49,0,0,1,19.8,6,32,32,0,0,1,4.89,4.11c.54.56.94,1,1.2,1.34l.29.36.07.09v0h0a1.25,1.25,0,0,0,2-1.46Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-1'
					d='M129.81,32.61h1.25A28.19,28.19,0,0,1,141,11.48a37.3,37.3,0,0,1,48.19,0,28.19,28.19,0,0,1,9.9,21.13,28.19,28.19,0,0,1-9.9,21.13,37.3,37.3,0,0,1-48.19,0,28.19,28.19,0,0,1-9.9-21.13h-2.5a30.69,30.69,0,0,0,10.75,23,39.8,39.8,0,0,0,51.5,0,30.69,30.69,0,0,0,10.74-23,30.69,30.69,0,0,0-10.74-23,39.8,39.8,0,0,0-51.5,0,30.69,30.69,0,0,0-10.75,23Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M33.1,87.61H2.44v66.84H34.17a1.25,1.25,0,0,0,0-2.5H4.94V90.11H33.1a1.25,1.25,0,0,0,0-2.5Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M25.23,117.91H3.69a1.25,1.25,0,1,0,0,2.5H25.23a1.25,1.25,0,1,0,0-2.5'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M348.71,87.61H318v66.84h31.72a1.25,1.25,0,0,0,0-2.5H320.55V90.11h28.16a1.25,1.25,0,0,0,0-2.5Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M342.16,117.91H320.62a1.25,1.25,0,0,0,0,2.5h21.54a1.25,1.25,0,0,0,0-2.5'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M81.82,108.59C80.5,101.23,78,96,74.58,92.63a16.7,16.7,0,0,0-12.16-4.87,14.92,14.92,0,0,0-8.15,2.45c-3.72,2.4-6.79,6.43-8.93,11.77A53.51,53.51,0,0,0,42,121.77c0,12.25,2.16,20.67,5.84,26.16a17.65,17.65,0,0,0,6.57,6,17.42,17.42,0,0,0,8,1.85A16.7,16.7,0,0,0,75,150.54c3.46-3.66,6-9.27,7.14-17.23a1.25,1.25,0,0,0-2.47-.37c-1.14,7.63-3.5,12.73-6.48,15.88a14.17,14.17,0,0,1-10.71,4.47,14.91,14.91,0,0,1-6.9-1.58c-3.14-1.62-5.87-4.56-7.86-9.41s-3.19-11.56-3.19-20.53c0-10,2.12-18,5.44-23.37a18.87,18.87,0,0,1,5.72-6.09,12.4,12.4,0,0,1,6.8-2.05A14.18,14.18,0,0,1,72.82,94.4c2.93,2.91,5.28,7.6,6.54,14.63a1.25,1.25,0,0,0,2.46-.44Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M204.38,86.72a14.91,14.91,0,0,0-8.15,2.45c-3.72,2.4-6.79,6.43-8.93,11.77a53.51,53.51,0,0,0-3.37,19.79c0,12.25,2.16,20.67,5.84,26.16a17.64,17.64,0,0,0,6.57,6,17.42,17.42,0,0,0,8,1.85,1.25,1.25,0,0,0,0-2.5,14.91,14.91,0,0,1-6.9-1.58c-3.14-1.62-5.87-4.56-7.86-9.41s-3.19-11.56-3.19-20.53c0-10,2.12-18,5.44-23.37a18.86,18.86,0,0,1,5.71-6.09,12.4,12.4,0,0,1,6.8-2.05,1.25,1.25,0,0,0,0-2.5Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M204.38,89.22a12.4,12.4,0,0,1,6.8,2.05c3.17,2,6,5.62,8,10.6a51.06,51.06,0,0,1,3.19,18.86c0,12-2.14,19.92-5.41,24.76a15.14,15.14,0,0,1-5.64,5.18,14.91,14.91,0,0,1-6.9,1.58,1.25,1.25,0,0,0,0,2.5,17.42,17.42,0,0,0,8-1.85c3.75-1.92,6.88-5.43,9-10.68s3.37-12.29,3.37-21.48c0-10.37-2.18-18.77-5.81-24.68a21.35,21.35,0,0,0-6.49-6.88,14.91,14.91,0,0,0-8.15-2.45,1.25,1.25,0,0,0,0,2.5Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M95,89.21v64.34a1.25,1.25,0,0,0,2.5,0V89.21a1.25,1.25,0,0,0-2.5,0'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M124.73,88.52l-28.6,43.12A1.25,1.25,0,0,0,98.21,133l28.6-43.12a1.25,1.25,0,0,0-2.08-1.38'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M129.23,153,111.36,113a1.25,1.25,0,1,0-2.28,1l17.87,40.05a1.25,1.25,0,1,0,2.28-1'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M171.77,88.86V153.2a1.25,1.25,0,0,0,2.5,0V88.86a1.25,1.25,0,1,0-2.5,0'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M141.1,153.2V88.86a1.25,1.25,0,0,0-2.5,0V153.2a1.25,1.25,0,0,0,2.5,0'
					transform='translate(0 0)'
				/>
				<polygon
					className='cls-2'
					points='173.02 119.8 173.02 118.55 140.23 118.55 140.23 121.05 173.02 121.05 173.02 118.55 173.02 119.8 173.02 118.55 140.23 118.55 140.23 121.05 173.02 121.05 173.02 118.55 173.02 119.8'
				/>
				<path
					className='cls-2'
					d='M42.44,261.12v57.54a1.25,1.25,0,0,0,2.5,0V261.12a1.25,1.25,0,0,0-2.5,0'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M4.6,318.67V261.12a1.25,1.25,0,0,0-2.5,0v57.54a1.25,1.25,0,0,0,2.5,0'
					transform='translate(0 0)'
				/>
				<polygon
					className='cls-2'
					points='43.69 292.18 43.69 290.93 3.73 290.93 3.73 293.43 43.69 293.43 43.69 290.93 43.69 292.18 43.69 290.93 3.73 290.93 3.73 293.43 43.69 293.43 43.69 290.93 43.69 292.18'
				/>
				<path
					className='cls-2'
					d='M232.79,89.21v48.08a18.82,18.82,0,0,0,5.1,13,16.9,16.9,0,0,0,24.79,0,18.82,18.82,0,0,0,5.1-13V89.21a1.25,1.25,0,0,0-2.5,0v48.08a16.32,16.32,0,0,1-4.42,11.26,14.4,14.4,0,0,1-21.16,0,16.32,16.32,0,0,1-4.42-11.26V89.21a1.25,1.25,0,0,0-2.5,0Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M309.27,105.38a25,25,0,0,0-2.39-8.94A17.64,17.64,0,0,0,301.75,90a16.93,16.93,0,0,0-9.61-3.3l-.81,0A15.62,15.62,0,0,0,281,90.54a13.1,13.1,0,0,0-4.51,9.88c0,.4,0,.81.06,1.22l1.25-.12-1.24-.19a3.35,3.35,0,0,0,0,.49c0,1,.29,3.31,2.38,6.5s6,7.23,13.12,11.76a1.25,1.25,0,0,0,1.34-2.11c-6.93-4.39-10.52-8.21-12.37-11s-2-4.62-2-5.13v-.13h0l-.71-.08.7.11v0l-.71-.08.7.11,0-.15v-.15c0-.33,0-.66,0-1a10.59,10.59,0,0,1,3.67-8,13.13,13.13,0,0,1,8.69-3.21l.69,0a14.42,14.42,0,0,1,8.22,2.8,16.71,16.71,0,0,1,5.56,8.47,23.89,23.89,0,0,1,.82,3.59c.07.45.11.82.13,1.06s0,.22,0,.28v.07h0a1.25,1.25,0,0,0,2.5-.11Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M274.7,136.36a26,26,0,0,0,2.48,9.1,18.14,18.14,0,0,0,5.19,6.53,16.86,16.86,0,0,0,9.64,3.36q.61,0,1.23,0a17.17,17.17,0,0,0,11.12-3.57,13.42,13.42,0,0,0,3.78-5.19,20,20,0,0,0,1.4-7.79q0-.31,0-.64v0a20,20,0,0,0-.5-4.4,15.92,15.92,0,0,0-4.21-7.26c-2.43-2.57-6-5.33-11.52-8.55a1.25,1.25,0,1,0-1.27,2.16c7.13,4.19,10.86,7.52,12.77,10.32a11.79,11.79,0,0,1,1.8,3.93,17.53,17.53,0,0,1,.43,3.86h0q0,.3,0,.59a17.57,17.57,0,0,1-1.21,6.83,10.81,10.81,0,0,1-5.14,5.6,16.31,16.31,0,0,1-7.46,1.63l-1.1,0a14.35,14.35,0,0,1-8.25-2.84,17.34,17.34,0,0,1-5.66-8.65,25,25,0,0,1-.86-3.67c-.07-.46-.11-.84-.14-1.09s0-.22,0-.28v-.07h0a1.25,1.25,0,1,0-2.5.13Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M65.07,319.53l24.09-57.11,25.7,57.14a1.25,1.25,0,0,0,2.28-1L90.26,258.78a1.25,1.25,0,0,0-2.29,0l-25.2,59.75a1.25,1.25,0,1,0,2.3,1Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M72.49,301.69h34.9a1.25,1.25,0,1,0,0-2.5H72.49a1.25,1.25,0,0,0,0,2.5'
					transform='translate(0 0)'
				/>
				<path
					className='cls-1'
					d='M2.78,177.76H1.53V231l1.2,0c5,.2,13.19.31,22.42.39h2.22c13.34,0,26.91-1,37.37-4.7,5.23-1.84,9.7-4.34,12.92-7.79a19.25,19.25,0,0,0,5.24-13.22h0q0-.28,0-.55a22.77,22.77,0,0,0-5.08-15C73,184.34,65.18,181,56.06,179s-19.67-2.55-30.19-2.6H22.26c-7.89,0-14.86.12-19.48.12H1.53v1.25H2.78V179c4.66,0,11.62-.12,19.48-.12h3.6c13.91.07,27.76,1.09,37.94,4.93,5.09,1.91,9.25,4.51,12.11,8a20.25,20.25,0,0,1,4.5,13.43q0,.25,0,.51h0a16.72,16.72,0,0,1-4.56,11.56c-4.25,4.57-11.39,7.56-20,9.33S37.27,229,27.37,229h-2.2c-9.21-.08-17.37-.19-22.34-.38l0,1.25H4V177.76H2.78v0Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-1'
					d='M86,231.92,128.39,179l42.18,53.8a1.25,1.25,0,0,0,2-1.54l-43.15-55a1.25,1.25,0,0,0-2,0L84,230.36a1.25,1.25,0,1,0,2,1.56Z'
					transform='translate(0 0)'
				/>
				<line
					className='cls-1'
					x1='157.22'
					y1='214.19'
					x2='99.34'
					y2='213.89'
				/>
				<path
					className='cls-1'
					d='M157.22,212.94l-57.87-.3a1.25,1.25,0,0,0,0,2.5l57.87.3a1.25,1.25,0,0,0,0-2.5'
					transform='translate(0 0)'
				/>
				<line
					className='cls-1'
					x1='344.25'
					y1='232.14'
					x2='344.25'
					y2='175.07'
				/>
				<path
					className='cls-1'
					d='M345.5,232.14V175.07a1.25,1.25,0,0,0-2.5,0v57.06a1.25,1.25,0,0,0,2.5,0'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M345.23,272.47a16.46,16.46,0,0,0-7.44-9.75,28.18,28.18,0,0,0-12.79-4c-1.2-.1-2.44-.15-3.71-.15a31.8,31.8,0,0,0-13,2.45,16.51,16.51,0,0,0-5.26,3.69,13.76,13.76,0,0,0-3.24,5.86,17.83,17.83,0,0,0-.67,4.67,11.53,11.53,0,0,0,2,6.69,17.41,17.41,0,0,0,8,5.82,64.62,64.62,0,0,0,9.64,2.85c2.73.64,5.69,1.14,8.58,1.75,4.34.91,8.53,2.1,11.49,4.22a11.07,11.07,0,0,1,3.51,4,13.09,13.09,0,0,1,1.33,6.12c0,.11,0,.22,0,.34h0a11.68,11.68,0,0,1-1.89,6.47,14.52,14.52,0,0,1-7.69,5.35,32.11,32.11,0,0,1-10,1.49,33.07,33.07,0,0,1-4.82-.34A31.42,31.42,0,0,1,307,315.1a21.92,21.92,0,0,1-8.84-10.62,1.25,1.25,0,1,0-2.35.84,24.39,24.39,0,0,0,9.81,11.86,33.87,33.87,0,0,0,13.27,5.2,35.52,35.52,0,0,0,5.19.36c4.52,0,9.84-.75,14.21-3.09a15.18,15.18,0,0,0,5.54-4.86,14.2,14.2,0,0,0,2.31-7.82h0c0-.12,0-.24,0-.36a14.62,14.62,0,0,0-2.8-9.14,15.3,15.3,0,0,0-4.93-4.11,36.36,36.36,0,0,0-9.49-3.25c-3.33-.74-6.69-1.29-9.64-2-4-.94-8.59-2.08-12-4.06a12.32,12.32,0,0,1-4.11-3.59,9,9,0,0,1-1.56-5.27,15.34,15.34,0,0,1,.59-4,11.25,11.25,0,0,1,2.66-4.81,15.84,15.84,0,0,1,7.22-4.14,33,33,0,0,1,9.2-1.2c1.19,0,2.37.05,3.51.14a25.72,25.72,0,0,1,11.64,3.65,14,14,0,0,1,6.36,8.26,1.25,1.25,0,0,0,2.42-.62Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M186.55,319.74V263.11l36.18,57.29a1.25,1.25,0,0,0,2.31-.67V258.79a1.25,1.25,0,1,0-2.5,0v56.63l-36.18-57.29a1.25,1.25,0,0,0-2.31.67v60.95a1.25,1.25,0,0,0,2.5,0Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M281.88,259.19H247.61a1.25,1.25,0,0,0-1.25,1.25v59.3a1.25,1.25,0,0,0,1.25,1.25h36a1.25,1.25,0,0,0,0-2.5h-34.7v-56.8h33a1.25,1.25,0,0,0,0-2.5Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M247.45,290l.38,0c1,0,3.86,0,7.48,0,8.25,0,20.12-.06,20.12-.06a1.25,1.25,0,0,0,0-2.5h-2c-3.72,0-11.95.05-18.12.05h-5.25l-1.65,0h-.66l-.05.52.07-.52h0l-.05.52.07-.52a1.25,1.25,0,1,0-.31,2.48Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M120.32,260.4,143,291.72a1.25,1.25,0,0,0,2,0l21.79-31.32a1.25,1.25,0,0,0-2.05-1.43L144,288.83l-21.69-29.9a1.25,1.25,0,1,0-2,1.47Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M145.18,319.74V291a1.25,1.25,0,0,0-2.5,0v28.75a1.25,1.25,0,0,0,2.5,0'
					transform='translate(0 0)'
				/>
				<path
					className='cls-1'
					d='M313.6,357.28h0c19.79,0,34.8,14.32,34.79,39.36S333.4,436,313.6,436s-33.91-14.32-33.92-39.36,14.12-39.38,33.92-39.36m0,2.83h0c-19.67,0-31.08,15.19-31.08,36.53s11.41,36.51,31.08,36.53,32-15.19,32-36.53-12.29-36.51-32-36.53'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M7.12,435.06A1.27,1.27,0,0,1,5.7,433.8V359a1.27,1.27,0,0,1,1.42-1.26A1.27,1.27,0,0,1,8.54,359v73.18H48.93a1.42,1.42,0,0,1,0,2.83Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M125.77,433.23l-30.28-74.6a1.74,1.74,0,0,0-1.7-1.28,1.94,1.94,0,0,0-1.73,1.31L59.51,433.23c-.4.92-.12,1.93,1.32,1.93A1.68,1.68,0,0,0,62.27,434l10.13-23.53h41.16L123,434.08a1.57,1.57,0,0,0,1.4,1.08c1.45,0,1.78-.91,1.38-1.93m-52.25-25.3L93.74,361l18.79,46.94Z'
					transform='translate(0 0)'
				/>
				<path
					className='cls-1'
					d='M264.71,379a1.25,1.25,0,0,0,1.63.95,1.28,1.28,0,0,0,1.15-1.51c-2.65-14.84-13.78-21.13-24-21.13-16.07,0-26.53,16.52-26.53,40S228.52,436,244,436c10.29,0,21.89-6.38,24.5-21.51a1.42,1.42,0,0,0-2.78-.55c-3,14.51-12.44,19.23-21.73,19.23-16.09,0-24.26-17.16-24.26-35.94s7-37.1,23.7-37.12c9.34,0,18.72,5.16,21.26,18.86'
					transform='translate(0 0)'
				/>
				<path
					className='cls-2'
					d='M167.54,357.68a1.53,1.53,0,0,1,1.56,1.28l16.85,70.63-.71-.4,18.92-70.24a1.57,1.57,0,0,1,1.63-1.38c1.41,0,1.73.94,1.43,2l-20.1,74.25a1.54,1.54,0,0,1-1.6,1.29,1.62,1.62,0,0,1-1.59-1.24l-16.38-70.75L151.12,433.9a1.62,1.62,0,0,1-1.59,1.24,1.54,1.54,0,0,1-1.6-1.29l-20.06-74.25c-.29-1.09,0-2,1.43-2a1.57,1.57,0,0,1,1.63,1.38l18.88,70.24-.71.4L166,359a1.53,1.53,0,0,1,1.56-1.28'
					transform='translate(0 0)'
				/>
				<line className='cls-1' x1='3.22' y1='342.99' x2='347.05' y2='342.99' />
				<path
					className='cls-1'
					d='M3.22,344H347a1,1,0,0,0,0-2H3.22a1,1,0,1,0,0,2'
					transform='translate(0 0)'
				/>
				<line
					className='cls-1'
					x1='252.74'
					y1='177.31'
					x2='329.17'
					y2='177.31'
				/>
				<path
					className='cls-1'
					d='M252.74,178.56h76.43a1.25,1.25,0,0,0,0-2.5H252.74a1.25,1.25,0,0,0,0,2.5'
					transform='translate(0 0)'
				/>
				<line
					className='cls-1'
					x1='291.74'
					y1='232.36'
					x2='291.74'
					y2='177.89'
				/>
				<path
					className='cls-1'
					d='M293,232.36V177.89a1.25,1.25,0,0,0-2.5,0v54.47a1.25,1.25,0,0,0,2.5,0'
					transform='translate(0 0)'
				/>
				<path
					className='cls-1'
					d='M77.73,2.71v60.8h48.68a1.25,1.25,0,0,0,0-2.5H80.23V2.71a1.25,1.25,0,0,0-2.5,0Z'
					transform='translate(0 0)'
				/>
			</svg>
		</LogoHolder>
	);
};
